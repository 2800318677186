import {
  __,
  flip,
  always,
  identity,
  adjust,
  append,
  concat,
  update,
  remove,
  either,
  both,
  all,
  allPass,
  anyPass,
  any,
  none,
  isNil,
  isEmpty,
  ifElse,
  defaultTo,
  unless,
  when,
  cond,
  is,
  not,
  complement,
  trim,
  //@ts-expect-error rambda issues
  contains,
  intersection,
  join,
  split,
  splitEvery,
  unnest,
  compose,
  pipe,
  map,
  mapAccum,
  mapObjIndexed,
  chain,
  ap,
  forEach,
  evolve,
  invertObj,
  addIndex,
  zip,
  zipObj,
  zipWith,
  uniq,
  partition,
  length,
  values,
  has,
  prop,
  propEq,
  propOr,
  pathOr,
  props,
  lensPath,
  lensProp,
  lensIndex,
  path,
  pick,
  pickBy,
  pluck,
  omit,
  curry,
  curryN,
  equals,
  lt,
  lte,
  gt,
  gte,
  apply,
  applyTo,
  startsWith,
  toLower,
  view,
  set,
  over,
  groupBy,
  indexBy,
  head,
  tail,
  init,
  last,
  take,
  reverse,
  keys,
  filter,
  without,
  where,
  find,
  reject,
  assoc,
  reduce,
  repeat,
  range,
  fromPairs,
  toPairs,
  //@ts-expect-error rambda issues
  merge,
  mergeAll,
  mergeWith,
  memoizeWith,
  sort,
  sortBy,
  sortWith,
  ascend,
  descend,
  tap,
  tryCatch,
  difference,
  differenceWith,
  xprod,
  transpose,
  converge,
  juxt,
  symmetricDifferenceWith,
  symmetricDifference,
  eqBy,
} from 'ramda';
import { renameKeys, isPlainObj, isArray } from 'ramda-adjunct';

// PAPH - Paritally application placeholder, alias to R.__, so we don't need to turn off eslint for the line every time we use R.__
const PAPH = __;

const R = {
  PAPH,
  flip,
  always,
  identity,
  adjust,
  append,
  concat,
  update,
  remove,
  either,
  both,
  all,
  allPass,
  anyPass,
  any,
  none,
  isNil,
  isEmpty,
  ifElse,
  defaultTo,
  unless,
  when,
  cond,
  is,
  not,
  complement,
  trim,
  contains,
  intersection,
  join,
  split,
  splitEvery,
  compose,
  pipe,
  map,
  mapAccum,
  mapObjIndexed,
  chain,
  ap,
  forEach,
  evolve,
  addIndex,
  zip,
  zipObj,
  zipWith,
  uniq,
  partition,
  length,
  values,
  has,
  prop,
  propEq,
  propOr,
  pathOr,
  props,
  lensPath,
  lensProp,
  lensIndex,
  path,
  pick,
  pickBy,
  pluck,
  omit,
  curry,
  curryN,
  equals,
  lt,
  lte,
  gt,
  gte,
  apply,
  applyTo,
  startsWith,
  toLower,
  view,
  set,
  over,
  groupBy,
  indexBy,
  head,
  tail,
  init,
  last,
  take,
  reverse,
  keys,
  filter,
  without,
  where,
  find,
  reject,
  assoc,
  reduce,
  repeat,
  range,
  fromPairs,
  toPairs,
  merge,
  mergeAll,
  mergeWith,
  memoizeWith,
  sort,
  sortBy,
  sortWith,
  ascend,
  descend,
  tap,
  tryCatch,
  difference,
  differenceWith,
  xprod,
  transpose,
  converge,
  juxt,
};

// Common constant-value functions for easy function compositions
const [fEmptyString, fEmptyArray, fTrue, fOne] = R.map(R.always, ['', [], true, 1]);

const joinWithSeparator = (separator: string) => (arr: string[]) => arr.join(separator);

const joinWithDot = (a: string[]) => joinWithSeparator('.')(a);
const joinWithComma = (a: string[]) => joinWithSeparator(',')(a);
const joinWithSpace = (a: string[]) => joinWithSeparator(' ')(a);

const fNot = predicate => R.compose(R.not, predicate);

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const isNeitherNilNorEmpty = fNot(isNilOrEmpty);

const notEquals = R.curry(fNot(R.equals));

const sanitizeString = R.ifElse(R.is(String), R.trim, fEmptyString);
const meaningfulString = R.compose(isNeitherNilNorEmpty, sanitizeString);
const notMeaningfulString = fNot(meaningfulString);

const mapIndexed = R.addIndex(R.map);

const enrichObjectsWith = (generator, f = R.identity) => mapIndexed((a, index) => R.merge(f(a), generator(index)));

const enrichObjectsWithId = enrichObjectsWith(id => ({ id }));

const extractAndRename = ([extract, rename]) => R.pipe(R.pick(extract), renameKeys(rename));
// mainly transforming from {a, b} to {a, b, b'}
const deriveAndRenameObject = (mapping, customize = () => ({})) => {
  const instructionsFrom = R.pipe(
    R.toPairs,
    R.map(([k, v]) => R.xprod([k], R.map(R.defaultTo(k), v))),
    R.transpose,
    R.converge(R.zip, R.map(R.map, [R.map(R.head), R.fromPairs])),
  );

  const transforms = R.map(extractAndRename, instructionsFrom(mapping));

  return R.pipe(R.juxt([...transforms, customize]), R.mergeAll);
};
const extractAndRenameKeys = mapping => extractAndRename([R.keys(mapping), mapping]);

const updateWithLens = R.curry((lens, key, value, source) => R.over(lens, R.assoc(key, value), source));
const mergeWithLens = R.curry((lens, entries, source) => R.over(lens, a => R.mergeAll([a, entries]), source));

// probably there's already something like that in R/RA
const transformWhen = R.curry((check, transform, source, defaultValue) => (check(source) ? transform(source) : defaultValue));

// This is similar to `applySpec`
const transformShape = R.curry((transformersOfShape, value): unknown[] =>
  R.ap([R.ifElse(R.either(isPlainObj, isArray), R.map(R.applyTo(value)), R.applyTo(value))], transformersOfShape),
);

const explodeOjValue = explode => R.pipe(R.toPairs, R.chain(explode), R.fromPairs);

const tokenize = phrase => (phrase || '').split(/\s*[\s,]\s*/).filter(Boolean);

const filterBy = (x, ys, readX = R.identity, readY = R.identity) => {
  if (isNilOrEmpty(ys)) {
    return [];
  }
  const filterString = sanitizeString(`${readX(x)}`);
  if (isNilOrEmpty(filterString)) {
    return ys;
  }
  const words = tokenize(R.toLower(filterString));
  if (R.isEmpty(words)) {
    return [];
  }

  const matching = sentence => R.all(a => R.contains(a, sentence), words);

  return R.filter(y => matching(R.toLower(`${readY(y)}`)), ys);
};

// @ts-expect-error intentionally breaks patters, is being used in a curried manner
const memoize = R.memoizeWith(fOne);
const isOneOf = R.flip(R.contains);

const randomInt = (max = 1000, min = 0, inclusive = false) => {
  const minn = Math.ceil(min);
  const maxx = Math.floor(max);
  const intRange = maxx - minn + (inclusive ? 1 : 0);

  return Math.floor(Math.random() * intRange) + minn;
};

const isOneElementArray = a => isArray(a) && a.length === 1;

const unixTimeWithMillis = () => new Date().getTime();

const setEquals = (set1, set2) => set1.size === set2.size && [...set1].every(value => set2.has(value));

const equalsByProps = (list1, list2, key) => {
  if ((!list1 && list2) || (list1 && !list2)) return false;

  if (!list1 && !list2) return true;

  let eq = eqBy(prop(key));

  if (Array.isArray(key)) {
    eq = (a, b) => key.reduce((acc, cur) => acc && a[cur] === b[cur], true);
  }

  return compose(isEmpty, symmetricDifferenceWith)(eq, list1, list2);
};

const equalsArray = (list1, list2) => {
  if ((!list1 && list2) || (list1 && !list2)) return false;

  if (!list1 && !list2) return true;

  return compose(isEmpty, symmetricDifference)(list1, list2);
};

const diffArray = (list1: readonly unknown[], list2: readonly unknown[]) => {
  if (!list1 && list2) return list2;

  if (list1 && !list2) return list1;

  if (!list1 && !list2) return [];

  return symmetricDifference(list1, list2);
};

export {
  fEmptyString,
  fEmptyArray,
  fTrue, // same but more readable than R.T
  fNot,
  isNilOrEmpty,
  isNeitherNilNorEmpty,
  notEquals,
  sanitizeString,
  meaningfulString,
  notMeaningfulString,
  joinWithDot,
  joinWithComma,
  joinWithSpace,
  mapIndexed,
  enrichObjectsWith,
  enrichObjectsWithId,
  deriveAndRenameObject,
  extractAndRenameKeys,
  updateWithLens,
  mergeWithLens,
  transformWhen,
  transformShape,
  explodeOjValue,
  tokenize,
  filterBy,
  memoize,
  isOneOf,
  randomInt,
  isOneElementArray,
  unixTimeWithMillis,
  R,
  setEquals,
  equalsByProps,
  equalsArray,
  diffArray,
};
