const dedupeStrategies = {
  softLock: 'SOFT_LOCK_DEDUPE',
  hardLock: 'HARD_LOCK_DEDUPE',
  none: 'NO_DEDUPE',
};

const triggerTypes = {
  location: 'LOCATION_ENTER',
  oneTime: 'ONE_TIME',
  recurring: 'RECURRING',
  cartAbandon: 'CART_ABANDON',
  orderAbandon: 'ORDER_ABANDON',
  eventsBased: 'REAL_TIME',
};

const triggerDisplayValues: Record<string, string> = {
  LOCATION_ENTER: 'On location enter',
  ONE_TIME: 'One time',
  RECURRING: 'Recurring',
  CART_ABANDON: 'On cart abandon',
  ORDER_ABANDON: 'On order abandon',
  REAL_TIME: 'Events based',
};

const defaultTriggerType = triggerTypes.oneTime;

const minDefaultRadius = 10;

const defaultTriggerTimeInMinutes = 30;

const locationTrigger = {
  name: triggerDisplayValues.LOCATION_ENTER,
  value: triggerTypes.location,
};

const oneTimeTrigger = {
  name: triggerDisplayValues.ONE_TIME,
  value: triggerTypes.oneTime,
};

const recurringTrigger = {
  name: triggerDisplayValues.RECURRING,
  value: triggerTypes.recurring,
};

const eventsBased = {
  name: triggerDisplayValues.REAL_TIME,
  value: triggerTypes.eventsBased,
};

const cartAbandonTrigger = {
  name: triggerDisplayValues.CART_ABANDON,
  value: triggerTypes.cartAbandon,
};

const campaignStates = {
  created: 'CREATED',
  draft: 'DRAFT',
  error: 'ERROR',
  finished: 'FINISHED',
  paused: 'PAUSED',
  started: 'STARTED',
  stopped: 'STOPPED',
};

const audienceTypes = {
  csv: 'csv',
  rule: 'rule',
} as const;

export type audienceTypesType = Array<(typeof audienceTypes)[keyof typeof audienceTypes]>;

const audienceTypesV2 = {
  csv: 'static',
  rule: 'rule-based',
  composition: 'composition',
} as const;

export type audienceTypesV2Type = Array<(typeof audienceTypesV2)[keyof typeof audienceTypesV2]>;

const audienceTypeDisplay = {
  csv: 'Upload Based',
  rule: 'Rules Based',
  composition: 'Composition Audience',
};

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']; // the order in the array matters, moment library needs index.

const handlebarsHelpers = [
  'eq',
  'neq',
  'lt',
  'gt',
  'and',
  'or',
  'not',
  'this',
  'dateFormat',
  'yesno',
  'with',
  'each',
  'index',
  'key',
  'if',
  'else',
  'unless',
];

const minuteInSeconds = 60;
const hourInSeconds = 3600;
const dayInSeconds = 86400;

const timeUnits = {
  mins: { value: 'mins', label: 'Mins' },
  hours: { value: 'hours', label: 'Hours' },
  days: { value: 'days', label: 'Days' },
};

const notificationlinksType = ['paypay://', 'https://', 'http://'];
const senderlinksType = ['https://', 'http://'];
const whitelistedDomains = ['paypay-corp.co.jp'];

export {
  audienceTypeDisplay,
  audienceTypes,
  audienceTypesV2,
  campaignStates,
  cartAbandonTrigger,
  dedupeStrategies,
  defaultTriggerTimeInMinutes,
  defaultTriggerType,
  eventsBased,
  handlebarsHelpers,
  locationTrigger,
  minDefaultRadius,
  oneTimeTrigger,
  recurringTrigger,
  triggerDisplayValues,
  triggerTypes,
  daysOfWeek,
  minuteInSeconds,
  hourInSeconds,
  dayInSeconds,
  timeUnits,
  notificationlinksType,
  senderlinksType,
  whitelistedDomains,
};
